<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="850"
    class="scroll"
  >
    <v-card
      class="px-5 pb-4 scroll"
    >
      <v-container fluid>
        <v-row justify="center"><div class="title black--text text-uppercase py-4">{{$t('tests')}}</div></v-row>
        <v-row class="mx-4 mt-0 scroll-test">
          <v-col cols="12" md="4" lg="4" xl="4" v-for="test in tests" :key="test.id">
            <test-card :test="test" @preview="openPreviewDialog" />
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end align-center px-2">
        <v-btn
          class="mt-4"
          color="primary"
          text
          @click="$emit('close')"
        >{{$t('close')}}</v-btn>
      </v-row>
      </v-container>
    </v-card>
 
    <preview-test-dialog :dialog="previewDialog" :test="test" @close="previewDialog = false" />
  </v-dialog>
</template>

<script>
import TestCard from '../../../components/TestCard.vue'
import PreviewTestDialog from './ReviewTestDialog.vue'
export default {
  name: 'ReviewTest',
  components: { PreviewTestDialog, TestCard },
  props: {
    dialog: Boolean,
    tests: Array
  },
  data: () => ({
    previewDialog: false,
    test: {}
  }),
  methods: {
    openPreviewDialog (test) {
      this.test = test
      this.previewDialog = true
    }
  }
}
</script>