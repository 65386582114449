

<script>
import { Bar } from 'vue-chartjs'
export default {
  name: 'BarChart',
  extends: Bar,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      defaultFontColor: '#000',
      scales: {
        yAxes: [{
          stacked: true,
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 100
          },
        }],
        xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero: true,
            categoryPercentage: 0.5,
            barPercentage: 1,
          },
        }],
      },
    }
  }),
  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>