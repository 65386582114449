<template>
  <v-dialog v-model="dialog" persistent max-width="750">
    <v-card class="pb-5 px-5" v-if="test.questions">
      <v-card-title class="justify-center">
        <span class="headline">{{ $t(test.area) }}</span>
      </v-card-title>
      <v-card-text>
        <v-row class="pt-1 justify-center" no-gutters>
          <v-col
            v-show="test.questions[position].picture"
            cols="12"
            md="12"
            class="pb-5 text-center justify-center"
          >
            <v-img
              :src="`${apiUrl}/images/test/${test.questions[position].picture}`"
              aspect-ratio="2"
              contain
            ></v-img>
          </v-col>
          <v-col cols="12" md="12" lg="12" xlg="12" class="text-center">
            <span class="title black--text"
              >{{ test.questions[position].question }}({{
                test.questions[position].percentage
              }}%)</span
            >
            <div v-if="test.questions[position].alternatives[0].answer">
            <p>
              <span style="color:green">{{$t('green')}}</span>: {{$t('green_text')}}
            </p>
            <p style="margin-top:-3%">
              <span style="color:red">{{$t('red')}}</span>: {{$t('red_text')}}
            </p>
            <p style="margin-top:-3%">
              <span style="color:orange">{{$t('orange')}}</span>: {{$t('orange_text')}}
            </p>
            </div>
            
          </v-col>
          <v-col cols="12" md="12" lg="12" xlg="12" class="text-center pa-2">
            <v-row
              no-gutters
              v-if="test.questions[position].alternatives[0].answer"
            >
              <v-col
                cols="12"
                md="12"
                class="pa-1"
                v-for="(alternative, index) in test.questions[position]
                  .alternatives"
                :key="index"
              >
               <v-card
                class="mx-auto"
                max-width="650"
                flat
                fluid
                style="border: 1px solid #0098CE;  border-radius: 5px;"
                :color="alternativeColor(alternative)!='primary'? alternativeColor(alternative):false"
                :outlined="alternativeColor(alternative) === 'primary'"
               
                @click="selectAnswer(index)"
              >
                <v-card-text style="padding-top: -20px">
                  <div
                    class="flex-grow-1 text-center"
                    :style="{ color: alternative.response ? '#fff' : alternativeColor(alternative) === 'primary'?'#000':'#fff' }"
                  >
                    {{ alternative.answer }}
                  </div>
                </v-card-text>
              </v-card>
                <!-- <v-btn
                  :color="alternativeColor(alternative)"
                  dark
                  tile
                  :outlined="alternativeColor(alternative) == '#F6A400'"
                  block
                  class="align-center py-3 "
                  @click="selectAnswer(index)"
                >
                 <div class="flex-grow-1 text-center" :style="{'color': alternative.response ? '#000':'#000'}" v-html=" alternative.answer.length> 55 ? alternative.answer.substring(0, 55)+'-<br/>'+alternative.answer.substring(55, alternative.answer.length):alternative.answer.substring(0, alternative.answer.length)">
            
                 </div>
                </v-btn> -->
              </v-col>
            </v-row>
            <v-textarea
              v-else
              v-model="test.questions[position].answer"
              rows="3"
              readonly
              :label="$t('answer')"
              class="pt-5"
            ></v-textarea>
          </v-col>
          <v-row class="pt-2 justify-center px-3" no-gutters>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-expansion-panels class="mx-auto ">
              <v-expansion-panel style="box-shadow: 0px 0px 0px 0px">
                <v-expansion-panel-header class="primary--text"
                  >{{$t('test_review_title')}}</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-col
                    cols="12"
                    md="12"
                    class="text-left"
                    v-if="test.questions[position].objective"
                  >
                    <p class="subtitle-2 black--text">
                      <span class="font-weight-black"
                        >{{$t('test_review_subtitle')}}</span
                      ><br />
                      {{ test.questions[position].objective }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    class="text-left"
                    v-if="test.questions[position].implication"
                  >
                    <p class="subtitle-2 black--text">
                      <span class="font-weight-black"
                        >{{$t('test_review_subtitle_2')}}</span
                      ><br />
                      {{ test.questions[position].implication }}
                    </p>
                  </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="d-flex flex-row justify-end mt-8 mb-2 px-6" no-gutters>
          <v-btn
            :disabled="position <= 0"
            color="primary"
            text
            @click="backQuestion()"
          >
            {{$t('Back')}}
          </v-btn>
          <v-btn
            v-if="position < test.questions.length - 1"
            color="primary"
            tile
            @click="nextQuestion()"
          >
            {{$t('next')}} {{ position + 1 }}/{{ test.questions.length }}
          </v-btn>
          <v-btn v-else color="primary" tile @click="nextQuestion()"
            >{{$t('finish')}} {{ position + 1 }}/{{ test.questions.length }}</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { API_URL } from "@/api";
export default {
  name: "PreviewTestDialog",
  props: {
    dialog: Boolean,
    test: Object,
  },
  data: () => ({
    apiUrl: API_URL,
    position: 0,
    questions: [
      {
        question: "",
        alternatives: [
          {
            answer: "",
            value: false,
            response: false,
          },
        ],
        totalCorrect: 0,
        status: false,
        picture: undefined,
      },
    ],
  }),
  created() {
    this.position = 0;
  },
  methods: {
    alternativeColor(alternative) {
      if (alternative.response && alternative.value) {
        return "success";
      } else if (alternative.response && !alternative.value) {
        return "error";
      } else if (alternative.value) {
        return "orange";
      } else {
        return "primary";
      }
    },
    nextQuestion() {
      if (this.position < this.test.questions.length - 1) {
        this.position += 1;
      } else {
        this.position = 0;
        this.$emit("close");
      }
    },
    backQuestion() {
      if (this.position > 0) {
        this.position -= 1;
      }
    },
  },
};
</script>
