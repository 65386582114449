var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-heigt",attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-0"},[_c('v-col',{attrs:{"cols":"11","md":"7"}},[_c('div',{staticClass:"text-left black--text",staticStyle:{"margin-top":"3%"}},[_vm._v(" Ranking ")])]),_c('v-col',{staticClass:"text-right px-0",attrs:{"cols":"1","md":"1"}}),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"text-right"},[_c('v-autocomplete',{attrs:{"item-text":"title","item-value":"id","items":_vm.vacancies,"outlined":"","label":_vm.$t('vacancy'),"dense":"","hide-details":""},on:{"change":function($event){return _vm.changeVacancy()}},model:{value:(_vm.vacancyId),callback:function ($$v) {_vm.vacancyId=$$v},expression:"vacancyId"}})],1)])],1),_c('v-divider',{staticClass:"my-3"}),_c('v-row',{staticClass:" mt-5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","md":"9"}},[[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.accessLevel(_vm.currentUser, 'schedule_interview'))?_c('v-btn',_vm._g({attrs:{"disabled":!_vm.isSelected,"color":"primary"},on:{"click":function($event){_vm.dialogInterview = true}}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar")]),_vm._v(_vm._s(_vm.$t("schedule_interview"))+" ")],1):_vm._e()]}}])},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('tooltip_interview'))}})])],[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.accessLevel(_vm.currentUser, 'send_test'))?_c('v-btn',_vm._g({staticStyle:{"margin-left":"5px"},attrs:{"disabled":!_vm.isSelected,"text":"","color":"primary"},on:{"click":function($event){_vm.dialogTest = true}}},on),[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("mdi-send")]),_vm._v(_vm._s(_vm.$t("send_test"))+" ")],1):_vm._e()]}}])},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('tooltip_sendtest_candidate'))}})])],(_vm.accessLevel(_vm.currentUser, 'schedule_interview'))?_c('v-btn',{staticStyle:{"display":"none"},attrs:{"disabled":!_vm.isSelected,"color":"primary"},on:{"click":function($event){_vm.dialogInterview = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar")]),_vm._v(_vm._s(_vm.$t("schedule_interview"))+" ")],1):_vm._e()],2),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('searchCandidate'),"single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.textSearch),callback:function ($$v) {_vm.textSearch=$$v},expression:"textSearch"}})],1)],1),_c('v-row',{staticClass:"px-0"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.$apollo.queries.vacancies.loading)?_c('v-card',[_c('v-skeleton-loader',_vm._b({attrs:{"type":("table-thead, table-row-divider@" + (_vm.filteredItems.length) + ", table-tfoot")}},'v-skeleton-loader',_vm.filteredItems,false))],1):_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"search":_vm.textSearch,"show-select":"","show-expand":"","expand-icon":"mdi-trending-up","color":"primary","dense":"","no-data-text":_vm.$t('tooltip_ranking')},scopedSlots:_vm._u([{key:"item.photo",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticStyle:{"margin-left":"-60%"},attrs:{"size":"40px"}},[_c('img',{attrs:{"src":item.candidate.photo
                    ? (_vm.apiUrl + "/images/candidate/" + (item.candidate.photo))
                    : "/avatar.png","alt":"John"}})])]}},{key:"item.fullName",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.candidate.fullName))])]}},{key:"item.profession",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.candidate.profession ? item.candidate.profession.substring(0, 150) : ""))])]}},{key:"item.punctuation",fn:function(ref){
                    var item = ref.item;
return [(item.tests.length > 0)?_c('v-progress-linear',{attrs:{"value":_vm.calculePontuation(item.tests),"color":_vm.setColor(_vm.calculePontuation(item.tests)),"height":"16","readonly":""}},[_c('span',{staticClass:"text--white"},[_vm._v(_vm._s(_vm.calculePontuation(item.tests))+"%")])]):_vm._e()]}},{key:"item.review",fn:function(ref){
                    var item = ref.item;
return [[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"},on:{"click":function($event){return _vm.reviewTest(item.tests)}}},on),[_vm._v("mdi-clipboard-text-search")])]}}],null,true)},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('tooltip_ranking_review'))}})])]]}},{key:"item.notes",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"},on:{"click":function($event){return _vm.openNoteDialog(item)}}},_vm.on),[_vm._v("mdi-comment-edit")])]}},{key:"item.profile",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{staticClass:"ma-1 pa-0",attrs:{"color":"blue","x-small":"","height":"30px","dark":""},on:{"click":function($event){return _vm.go(item.candidate.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}},{key:"expanded-item",fn:function(ref){
                    var headers = ref.headers;
                    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-col',{staticClass:"text-center justify-center",attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-card',[_c('bar-chart',{attrs:{"chartdata":_vm.chartData(item.tests)}})],1)],1)],1)]}}]),model:{value:(_vm.selectedCandidates),callback:function ($$v) {_vm.selectedCandidates=$$v},expression:"selectedCandidates"}})],1)],1)],1),_c('info-view-dialog',{attrs:{"dialog":_vm.infoDialog,"data":_vm.infoView},on:{"close":function($event){_vm.infoDialog = false}}}),(_vm.candidate.candidate.id)?_c('note-candidate-dialog',{attrs:{"candidateId":_vm.candidate.candidate,"dialog":_vm.noteDialog,"ranking":true,"vacancyId":_vm.vacancy},on:{"close":function($event){_vm.noteDialog = false}}}):_vm._e(),_c('review-test',{attrs:{"dialog":_vm.reviewDialog,"tests":_vm.tests},on:{"close":function($event){_vm.reviewDialog = false}}}),_c('schedule-interview-dialog',{attrs:{"dialog":_vm.dialogInterview,"selectedCandidates":_vm.selected,"vacancyId":_vm.vacancyId},on:{"close":function($event){_vm.dialogInterview = false}}}),_c('select-test',{attrs:{"selectedCandidates":_vm.selected,"dialog":_vm.dialogTest,"vacancyId":_vm.vacancyId},on:{"close":function($event){_vm.dialogTest = false},"done":function($event){return _vm.dialogTestDone()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }