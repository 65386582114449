<template>
  <div class="fill-heigt" fluid>
    <v-row class="px-0">
      <v-col cols="11" md="7">
        <div class="text-left black--text" style="margin-top:3%">
          Ranking
        </div>
      </v-col>
      <v-col cols="1" md="1" class="text-right px-0">
        <!-- <v-btn icon color="primary" @click="infoDialog = true">
          <v-icon>info</v-icon>
        </v-btn> -->
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right">
          <v-autocomplete
            v-model="vacancyId"
            item-text="title"
            item-value="id"
            :items="vacancies"
            outlined
            :label="$t('vacancy')"
            dense
            hide-details
            @change="changeVacancy()"
          ></v-autocomplete>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>

    <v-row class=" mt-5" no-gutters>
      <v-col cols="12" md="9" class="text-left">
        <template>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                v-if="accessLevel(currentUser, 'schedule_interview')"
                :disabled="!isSelected"
                color="primary"
                @click="dialogInterview = true"
              >
                <v-icon left>mdi-calendar</v-icon>{{ $t("schedule_interview") }}
              </v-btn>
            </template>
            <div v-html="$t('tooltip_interview')"></div>
          </v-tooltip>
        </template>

        <template>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                v-if="accessLevel(currentUser, 'send_test')"
                :disabled="!isSelected"
                text
                @click="dialogTest = true"
                style="margin-left:5px"
                color="primary"
              >
                <v-icon left color="primary">mdi-send</v-icon
                >{{ $t("send_test") }}
              </v-btn>
            </template>
            <div v-html="$t('tooltip_sendtest_candidate')"></div>
          </v-tooltip>
        </template>

        <v-btn
            style="display: none"
                v-if="accessLevel(currentUser, 'schedule_interview')"
                :disabled="!isSelected"
                color="primary"
                @click="dialogInterview = true"
              >
                <v-icon left>mdi-calendar</v-icon>{{ $t("schedule_interview") }}
              </v-btn>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="textSearch"
          append-icon="mdi-magnify"
          :label="$t('searchCandidate')"
          single-line
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="px-0">
      <v-col cols="12" md="12">
        <v-card v-if="$apollo.queries.vacancies.loading">
          <v-skeleton-loader
            v-bind="filteredItems"
            :type="
              `table-thead, table-row-divider@${filteredItems.length}, table-tfoot`
            "
          ></v-skeleton-loader>
        </v-card>
        <v-card v-else>
          <v-data-table
            v-model="selectedCandidates"
            :headers="headers"
            :items="filteredItems"
            :search="textSearch"
            show-select
            show-expand
            expand-icon="mdi-trending-up"
            color="primary"
            class="elevation-1"
            dense
            :no-data-text="$t('tooltip_ranking')"
          >
            <template v-slot:item.photo="{ item }">
              <v-avatar size="40px" style="margin-left:-60%; ">
                <img
                  :src="
                    item.candidate.photo
                      ? `${apiUrl}/images/candidate/${item.candidate.photo}`
                      : `/avatar.png`
                  "
                  alt="John"
                />
              </v-avatar>
            </template>
            <template v-slot:item.fullName="{ item }">
              <span class="text-caption">{{ item.candidate.fullName }}</span>
            </template>
            <template v-slot:item.profession="{ item }"
              ><span class="text-caption">{{
                item.candidate.profession
                  ? item.candidate.profession.substring(0, 150)
                  : ""
              }}</span></template
            >
            <template v-slot:item.punctuation="{ item }">
              <v-progress-linear
                v-if="item.tests.length > 0"
                :value="calculePontuation(item.tests)"
                :color="setColor(calculePontuation(item.tests))"
                height="16"
                readonly
              >
                <span class="text--white"
                  >{{ calculePontuation(item.tests) }}%</span
                >
              </v-progress-linear>
            </template>
            <template v-slot:item.review="{ item }">
              <template>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="primary"
                      @click="reviewTest(item.tests)"
                      >mdi-clipboard-text-search</v-icon
                    >
                  </template>
                  <div v-html="$t('tooltip_ranking_review')"></div>
                </v-tooltip>
              </template>
            </template>
            <template v-slot:item.notes="{ item }">
              <v-icon v-on="on" color="primary" @click="openNoteDialog(item)"
                >mdi-comment-edit</v-icon
              >
            </template>
            <template v-slot:item.profile="{ item }">
              <v-btn
                color="blue"
                x-small
                height="30px"
                class="ma-1 pa-0"
                dark
                @click="go(item.candidate.id)"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-col
                  cols="12"
                  md="12"
                  lg="12"
                  class="text-center justify-center"
                >
                  <v-card>
                    <bar-chart :chartdata="chartData(item.tests)" />
                  </v-card>
                </v-col>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <info-view-dialog
      :dialog="infoDialog"
      :data="infoView"
      @close="infoDialog = false"
    />
    <note-candidate-dialog
      v-if="candidate.candidate.id"
      :candidateId="candidate.candidate"
      :dialog="noteDialog"
      :ranking="true"
      :vacancyId="vacancy"
      @close="noteDialog = false"
    />
    <review-test
      :dialog="reviewDialog"
      :tests="tests"
      @close="reviewDialog = false"
    />
    <schedule-interview-dialog
      :dialog="dialogInterview"
      :selectedCandidates="selected"
      :vacancyId="vacancyId"
      @close="dialogInterview = false"
    />
    <select-test
      :selectedCandidates="selected"
      :dialog="dialogTest"
      :vacancyId="vacancyId"
      @close="dialogTest = false"
      @done="dialogTestDone()"
    />
  </div>
</template>

<script>
import { API_URL } from "@/api";
import {
  GET_PUBLISHED_VACANCIES_QUERY,
  GET_VACANCY_BY_ID_QUERY,
} from "../graphql/Query.resolver";
import { SOLVED_TEST_SUBSCRIPTION } from "./../graphql/Subscraption.resolver";
import { mapGetters } from "vuex";
import BarChart from "./../components/BarChart.vue";
import InfoViewDialog from "../../../components/InfoViewDialog.vue";
import moment from "moment";
import NoteCandidateDialog from "./../components/NoteCandidateDialog.vue";
import ReviewTest from "../components/ReviewTestList.vue";
import ScheduleInterviewDialog from "./../components/ScheduleInterviewDialog";
import SelectTest from "./../components/SelectTest.vue";
import accessLevelMixins from "@/mixins/access-level";
export default {
  name: "Ranking",
  mixins: [accessLevelMixins],
  components: {
    BarChart,
    InfoViewDialog,
    NoteCandidateDialog,
    ReviewTest,
    ScheduleInterviewDialog,
    SelectTest,
  },
  data: () => ({
    candidate: {
      candidate: {},
      notes: [],
    },
    candidates: [],
    dialogInterview: false,
    dialogTest: false,
    filter: {
      areasInterest: undefined,
      belongsToMany: undefined,
      formation: [
        {
          course: undefined,
          level: undefined,
        },
      ],
      ids: undefined,
      languages: [
        {
          name: undefined,
          level: undefined,
        },
      ],
      limit: 4,
      maxYear: undefined,
      minYear: undefined,
      maxSalary: undefined,
      minSalary: undefined,
      page: 1,
      professionalExperience: undefined,
    },
    infoDialog: false,
    // infoView: {
    //   icon: "mdi-account-multiple-check-outline",
    //   title: "Ranking",
    //   content:
    //     "Acompanhe o desenpenho dos candidatos aos testes submetidos, Visualize os perfis, agende entrevistas individuais e colectivas.",
    // },
    noteDialog: false,
    reviewDialog: false,
    selectAll: false,
    selectedCandidates: [],
    tests: [],
    textSearch: "",
    vacancyId: null,
    vacancy: {},
    vacancies: [],
    apiUrl: API_URL,
  }),
  apollo: {
    vacancies: {
      query: GET_PUBLISHED_VACANCIES_QUERY,
      variables: { ranking: true },
      fetchPolicy: "no-cache",
    },
    vacancy: {
      query: GET_VACANCY_BY_ID_QUERY,
      variables() {
        return {
          id: this.vacancyId,
        };
      },
      fetchPolicy: "no-cache",
      skip() {
        return this.skipQuery;
      },
      result({ data, loading }) {
        if (!loading) {
          this.vacancy = data.vacancy;
          this.filter.ids = this.vacancy.candidacies.map((c) => c.candidate.id);
        }
      },
    },
    $subscribe: {
      solvedTest: {
        query: SOLVED_TEST_SUBSCRIPTION,
        result({ data }) {
          console.log(data.solvedTest);
          const index = this.vacancy.candidacies.findIndex(
            (c) => c.candidate.id == data.solvedTest.candidate
          );
          const testIndex = this.vacancy.candidacies[index].tests.findIndex(
            (t) => t.id == data.solvedTest.id
          );
          this.vacancy.candidacies[index].tests.splice(
            testIndex,
            1,
            data.solvedTest
          );
        },
      },
    },
  },
  watch: {
    vacancies: function(val) {
      if (!this.vacancyId) {
        this.vacancyId = val[0].id;
        this.changeVacancy();
      } else {
        this.changeVacancy();
      }
    },
  },
  created() {
    if (this.$route.query.vacancyId) {
      this.vacancyId = this.$route.query.vacancyId;
      // this.changeVacancy()
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    filteredItems() {
      let resultItems = this.vacancy.candidacies
        ? this.vacancy.candidacies.map((c) => ({
            ...c,
            fullName: c.candidate.fullName,
          }))
        : [];
      return resultItems;
    },
    headers() {
      return [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "photo",
          width: "1%",
        },

        {
          text: this.$t("form_candidate_name"),
          align: "start",
          sortable: true,
          value: "fullName",
          width: "28%",
        },
        {
          text: this.$t("form_candidate_job"),
          align: "start",
          sortable: true,
          value: "profession",
          width: "35%",
        },
        {
          text: this.$t("overallscore"),
          sortable: true,
          value: "punctuation",
          width: "17%",
        },
        {
          text: this.$t("Revision"),
          align: "center",
          sortable: false,
          value: "review",
          width: "1%",
        },
        {
          text: this.$t("Notepad"),
          align: "center",
          sortable: false,
          value: "notes",
          width: "15%",
        },
        {
          text: this.$t("form_candidate_profile"),
          align: "center",
          sortable: false,
          value: "profile",
          width: "1%",
        },
        { text: "", align: "center", value: "data-table-expand", width: "1%" },
      ];
    },
    isSelected() {
      return this.selectedCandidates.length > 0;
    },
    selected() {
      return this.selectedCandidates.map((c) => {
        const candidate = Object.assign({}, c.candidate);
        delete candidate.age;
        delete candidate.entities;
        delete candidate.fullName;

        return candidate;
      });
    },
    infoView() {
      return {
        icon: "mdi-account-multiple-check-outline",
        title: "Ranking",
        content: this.$t("info_ranking"),
      };
    },
  },
  methods: {
    async changeVacancy() {
      const index = this.vacancies.findIndex((v) => v.id == this.vacancyId);
      this.filter.ids = this.vacancies[index].candidacies.map(
        (c) => c.candidate
      );
      this.$apollo.queries.vacancy.skip = false;
      await this.$apollo.queries.vacancy.refetch();
    },
    calculePontuation(tests) {
      let total = tests.length;
      let result =
        tests.reduce((accumulator, item) => {
          if (item.type == "psychological") {
            total = total - 1;
            return accumulator + 0;
          } else if (item.result) {
            return accumulator + item.result;
          } else if (!this.days(item)) {
            return accumulator + 0;
          } else {
            total = total - 1;
            return accumulator + 0;
          }
        }, 0) / total;

      return result >= 0 ? Number(result.toFixed(2)) : 0;
    },
    chartData(tests) {
      let labels = [],
        data = [];

      if (tests) {
        for (let index = 0; index < tests.length; index++) {
          labels.push(tests[index].title);
          if (tests[index].result) {
            data.push(tests[index].result);
          } else {
            data.push("pontuação Manual");
          }
        }
      } else {
        labels.push(1);
      }

      const datasets = [
        {
          label: this.$t("overallscore"),
          backgroundColor: this.setColorToChart(data),
          borderColor: this.setBorderColorToChart(data),
          borderWidth: 1,
          data,
        },
      ];
      return { labels, datasets };
    },
    days(test) {
      if (test.status) {
        return { status: "Concluido", color: "success" };
      }

      const testDate = test.date + " " + test.time;
      const finalDate = moment(testDate, "YYYY-MM-DD H:mm").locale("pt");
      const now = moment().format("YYYY-MM-DD H:mm");

      return now < finalDate._i;
    },
    dialogTestDone() {
      this.dialogTest = false;
      this.changeVacancy();
    },
    go(candidateId) {
      this.$router.push({
        path: `/dashboard/entity/ranking/profile/${candidateId}`,
        query: {
          filter: this.filter,
          from: "ranking",
          vacancyId: this.vacancyId,
        },
      });
    },
    openNoteDialog(candidate) {
      this.candidate = candidate;
      this.noteDialog = true;
    },
    reviewTest(tests) {
      this.tests = tests;
      this.reviewDialog = true;
    },
    setColor(punctuation) {
      if (punctuation < 50) {
        return "red";
      } else if (punctuation < 70) {
        return "#FFC107";
      } else {
        return "green";
      }
    },
    setBorderColorToChart(values) {
      let data = [];

      data = values.map((v) => {
        if (Math.ceil(v) < 50) {
          return "rgb(244, 67, 54, 1)";
        } else if (Math.ceil(v) < 70) {
          return "rgb(255, 193, 7, 1)";
        } else {
          return "rgb(76, 175, 80, 1)";
        }
      });

      return data;
    },
    setColorToChart(values) {
      let data = [];

      data = values.map((v) => {
        if (Math.ceil(v) < 50) {
          return "rgb(244, 67, 54, 0.2)";
        } else if (Math.ceil(v) < 70) {
          return "rgb(255, 193, 7, 0.2)";
        } else {
          return "rgb(76, 175, 80, 0.2)";
        }
      });

      return data;
    },
  },
};
</script>
